import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewEncapsulation
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Subject, takeUntil } from "rxjs";
// import { MAT_DIALOG_DATA, MatDia÷logRef } from "@angular/material/dialog";
import { MatDialogModule, MAT_DIALOG_DATA, MatDialogRef, MatDialog } from "@angular/material/dialog";
import { BreakpointObserver, BreakpointState } from "@angular/cdk/layout";
import { ArchiveWindowService } from "./archive-window.service";
import { ArchiveTrashWindowMobileComponent } from "../archive-trash-window-mobile/archive-trash-window-mobile.component";

@Component({
  selector: "vp-archive-window",
  templateUrl: "./archive-window.component.html",
  styleUrls: ["./archive-window.component.scss"],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ArchiveWindowComponent implements OnInit, OnDestroy {

  channelsCount = 0;
  topicsCount = 0;
  chatCount = 0;
  workFlowCount = 0;
  selectedTabIndex = 0;
  isAlive$ = new Subject<boolean>();
  isMobileScreen: boolean = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private breakpointObserver: BreakpointObserver,
    private changeDetectorRef: ChangeDetectorRef,
    public _archiveWindowService: ArchiveWindowService,
    private dialog: MatDialog,
    private dialogRef?: MatDialogRef<ArchiveWindowComponent>
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.selectedTabIndex = params["selectedTabIndex"];
    });
    this.isMobileScreen = this.breakpointObserver.isMatched("(max-width: 767px)");
    this.breakpointObserver
      .observe(["(max-width: 767px)"])
      .pipe(takeUntil(this.isAlive$))
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.isMobileScreen = true;
        } else {
          this.isMobileScreen = false;
        }
        this.changeDetectorRef.markForCheck();
      });
  }

  ngOnDestroy() {
    this.isAlive$.next(false);
  }


  exitArchiveWindow() {
    this.dialog.closeAll();
    if (this.isMobileScreen) {
      this.dialogRef.close();
    this.dialog.open(ArchiveTrashWindowMobileComponent, {
      backdropClass: "archive-contacts-backdrop",
      panelClass: "mobile-dialog",
      width: "100vw",
      height: "100vh",
      maxHeight: "100vh",
      maxWidth: "100vw",
      autoFocus: true
    });
    }
    else
    {
      this.router.navigateByUrl("/");
    }
  }
}
