
/*
 * VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { ModuleWithProviders, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { LazyLoadImageModule } from "ng-lazyload-image";

import { OverlayService } from "./overlay.service";
import { MatMenuModule } from "@angular/material/menu";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatButtonModule } from "@angular/material/button";
import { MatGridListModule } from "@angular/material/grid-list";
import { MatSliderModule } from "@angular/material/slider";
import {
  BackdropOverlayComponent,
  PageNotFoundComponent,
  VpUserAvatarComponent,
} from "./components";

import {
  AutoFocusScrollPosDirective,
  DatepickerPosDirective,
  DelayDragLiftDirective,
  HighlightWaveDirective,
  PopoverPosDirective,
  TxtHighlightPosDirective
} from "./directives";

import { AboutDialogComponent } from "./components/about-dialog/about-dialog.component";
import { ServiceDeskComponent } from "./components/service-desk-dialog/service-desk-dialog.component";
import { HelpDialogComponent } from "./components/help-dialog/help-dialog.component";
import { VNCAllSettingsComponent } from "./components/all-settings/all-settings.component";

import { VNCDisableControlDirective } from "../directives/vnc-disable-control";
import { TagInputModule } from "ngx-chips";
import { VNCAutofocusDirective } from "app/shared/directives/autofocus.directive";
import { TranslateModule } from "@ngx-translate/core";
import { MessageService } from "./providers/message.service";
import { VNCEditProfileComponent } from "../talk/shared/components/profile/edit-profile.component";
import { VncTabsComponent } from "./components/vnc-tabs/vnc-tabs.component";
import { VncTabContentComponent } from "./components/vnc-tab-content/vnc-tab-content.component";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatListModule } from "@angular/material/list";
import { ElectronService } from "./providers/electron.service";
import { ElectronScreenSharingComponent } from "./components/electron-screen-sharing-dialog/electron-screen-sharing-dialog.component";
import { CreatePadComponent } from "./components/create-pad/create-pad.component";
import { PadMenuDialogComponent } from "./components/pad-dialog/pad-dialog.component";
import { SelectAppDialogComponent } from "./components/select-app-dialog/select-app-dialog.component";
import { ScrollingModule } from "@angular/cdk/scrolling";
import { ScrollingModule as ExperimentalScrollingModule } from "@angular/cdk-experimental/scrolling";
import { CircularMenuComponent } from "./components/circular-menu/circular-menu.component";
import { MatTooltipModule } from "@angular/material/tooltip";
import { ResizableDirective } from "./directives/resizable.directive";
import { DisplayTimePipe } from "./directives/displayTime.pipe";
import { ImageCropperModule } from "ngx-image-cropper";
import { GlobalSharedModule } from "app/global-shared.module";
import { VNCHeaderModule, VncLibraryModule, VNCUserPreviewService, VNCVerticalTabsModule } from "vnc-library";
import { VideoConferenceModule } from "app/talk/video-conference/video-conference.module";
import { VNCSettingsComponent } from "./components/settings";
import { MatSelectModule } from "@angular/material/select";
import { VNCCommonDialogComponent } from "./components/common-dialog/common-dialog.component";
import { ChangeUserInfoComponent } from "./components/change-user-info/change-user-info.component";
import { NotificationCenterComponent } from "./components/notification-center/notification-center.component";
import { SubscriptionCenterComponent } from "./components/subscription-center/subscription-center.component";
import { VNCMobileNotificationCenterComponent } from "./components/mobile-notification-center/mobile-notification-center.component";
import { TalkSharedModule } from "app/talk/shared/shared.module";
import {RelatedTopicsListComponent} from "./components/related-topics/related-topics-list/related-topics-list.component";
import {RelatedTopicsPopupComponent} from "./components/related-topics/related-topics-popup/related-topics-popup.component";
import {RelatedTopicsService} from "./components/related-topics/related-topics.service";
import { RelatedTopicPreviewComponent } from "./components/related-topics/related-topic-preview/related-topic-preview.component";
import { OwnCloudComponent } from "./components/owncloud/owncloud.component";
import  { MatProgressBarModule } from "@angular/material/progress-bar";
import { ResizableModule } from "angular-resizable-element";
import { NgxOnlyOfficeModule } from "ngx-onlyoffice";
import { VNCBottomSheetFileMenu } from "./components/bottom-sheet-file-menu/bottom-sheet-file-menu.component";
import { VNCBottomSheetChatActionsMenu } from "./components/bottom-sheet-chat-actions-menu/bottom-sheet-chat-actions-menu.component";
import { VNCBottomSheetAddActionsMenu } from "./components/bottom-sheet-add-action-menu/bottom-sheet-add-action-menu.component";
import { CallParticipantsComponent } from "./components/call-participants/call-participants.component";
import { AutosizeDirective } from "app/talk/shared/directives/autosize.directive";
import { CreateNewTicketComponent } from "./components/create-new-ticket/create-new-ticket.component";
import { QuillModule } from "ngx-quill";
import { SelectWatcherComponent } from "./components/select-watcher/select.watcher.component";
import { OverlayModule } from "@angular/cdk/overlay";
import { EscapeDirective } from "app/talk/shared/directives/esc.directive";
import { ColorControlComponent } from "./components/color-control/color-control.component";
import { ColorChromeModule } from "ngx-color/chrome";
import { GradientSpinnerComponent } from "./components/gradient-spinner/gradient-spinner.component";
import { VNCBottomSheetMobileCallMenu } from "./components/bottom-sheet-mobile-call/bottom-sheet-mobile-call.component";
import { DragDropShadeComponent } from "./components/drag-drop-shade/drag-drop-shade.component";

const DIRECTIVES = [
  VNCDisableControlDirective,
  TxtHighlightPosDirective,
  PopoverPosDirective,
  DatepickerPosDirective,
  AutoFocusScrollPosDirective,
  HighlightWaveDirective,
  DelayDragLiftDirective,
  VNCAutofocusDirective,
  ResizableDirective,
  DisplayTimePipe,
  AutosizeDirective,
  EscapeDirective
];

const DIALOGUES = [
  AboutDialogComponent,
  ElectronScreenSharingComponent,
  HelpDialogComponent,
  VNCAllSettingsComponent,
  CreatePadComponent,
  PadMenuDialogComponent,
  SelectAppDialogComponent,
  VNCSettingsComponent,
  VNCCommonDialogComponent,
  ChangeUserInfoComponent,
  VNCMobileNotificationCenterComponent,
  RelatedTopicsPopupComponent,
  RelatedTopicPreviewComponent,
  CreateNewTicketComponent,
  SelectWatcherComponent
];

const PIPES = [
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MatInputModule,
    ReactiveFormsModule,
    TagInputModule,
    LazyLoadImageModule,
    TranslateModule,
    MatMenuModule,
    MatButtonModule,
    MatSlideToggleModule,
    MatIconModule,
    MatListModule,
    MatSelectModule,
    MatTooltipModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    ScrollingModule,
    ExperimentalScrollingModule,
    MatGridListModule,
    ImageCropperModule,
    VNCHeaderModule,
    VNCVerticalTabsModule,
    VncLibraryModule,
    GlobalSharedModule,
    VideoConferenceModule,
    TalkSharedModule,
    MatSliderModule,
    MatProgressBarModule,
    VideoConferenceModule,
    ResizableModule,
    NgxOnlyOfficeModule,
    QuillModule.forRoot(),
    // import {OverlayModule} from '@angular/cdk/overlay';
    OverlayModule,

    ColorChromeModule,
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    MatMenuModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatProgressBarModule,
    MatIconModule,
    MatButtonModule,
    ScrollingModule,
    MatTooltipModule,
    ExperimentalScrollingModule,
    MatGridListModule,
    VncLibraryModule,
    VpUserAvatarComponent,
    PageNotFoundComponent,
    PageNotFoundComponent,
    GradientSpinnerComponent,
    AboutDialogComponent,
    ElectronScreenSharingComponent,
    ServiceDeskComponent,
    HelpDialogComponent,
    VNCAllSettingsComponent,
    BackdropOverlayComponent,
    VNCEditProfileComponent,
    VncTabsComponent,
    VncTabContentComponent,
    CircularMenuComponent,
    NotificationCenterComponent,
    SubscriptionCenterComponent,
    RelatedTopicsListComponent,
    RelatedTopicsPopupComponent,
    VNCBottomSheetFileMenu,
    VNCBottomSheetChatActionsMenu,
    VNCBottomSheetMobileCallMenu,
    VNCBottomSheetAddActionsMenu,
    OwnCloudComponent,
    CallParticipantsComponent,
    DragDropShadeComponent,

    ColorChromeModule,
    ColorControlComponent,
    // Directives
    ...DIRECTIVES,

    // Pipes
    ...PIPES,
    TranslateModule,
    ResizableModule
  ],
  declarations: [
    GradientSpinnerComponent,
    VpUserAvatarComponent,
    PageNotFoundComponent,
    ServiceDeskComponent,
    BackdropOverlayComponent,
    VNCEditProfileComponent,
    VncTabsComponent,
    VncTabContentComponent,
    CircularMenuComponent,
    NotificationCenterComponent,
    SubscriptionCenterComponent,
    RelatedTopicsListComponent,
    RelatedTopicsPopupComponent,
    RelatedTopicPreviewComponent,
    VNCBottomSheetFileMenu,
    VNCBottomSheetChatActionsMenu,
    VNCBottomSheetMobileCallMenu,
    VNCBottomSheetAddActionsMenu,
    OwnCloudComponent,
    DragDropShadeComponent,

    CallParticipantsComponent,
    ColorControlComponent,
    // Directives
    ...DIRECTIVES,

    // Dialogues
    ...DIALOGUES,

    // Pipes
    ...PIPES,
  ]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        OverlayService,
        MessageService,
        ElectronService,
        VNCUserPreviewService,
        RelatedTopicsService
      ]
    };
  }
}
