<div class="archive-channel-container" >
  <div class="channel-search-box">
    <vnc-input leadingIconName="mdi-magnify" placeholder="{{ 'SEARCH' | translate }}" [form]="searchControl"  [showClear]="true" clearTooltip="{{ 'CLEAR' | translate}}" ></vnc-input>
  </div>
  <div class="channels-bulk-option" *ngIf="(channels | async).length > 0">
    <p>{{totalChannels | async}} {{ 'CHANNELS' | translate }}</p>
    <mat-select panelClass="select-group-by" id="select-group-by" [formControl]="groupControl">
      <mat-option [value]="groupType.DATE_ASC">
        <div class="main-justify">
          <div>
            <!-- {{ 'SORT_BY_DATE' | translate }} -->
            <span>{{ 'SORT_BY_ASC' | translate }}</span>
          </div>
          <div class="icon-item">
            <svg *ngIf="groupControl.value === groupType.DATE_ASC" class="checked-icon" width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink">
              <defs>
                <filter x="-28.8%" y="-78.4%" width="157.5%" height="256.8%" filterUnits="objectBoundingBox" id="filter-1">
                  <feOffset dx="0" dy="8" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
                  <feGaussianBlur stdDeviation="8" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
                  <feColorMatrix values="0 0 0 0 0.031372549   0 0 0 0 0.176470588   0 0 0 0 0.239215686  0 0 0 0.2 0" type="matrix"
                    in="shadowBlurOuter1" result="shadowMatrixOuter1"></feColorMatrix>
                  <feOffset dx="0" dy="4" in="SourceAlpha" result="shadowOffsetOuter2"></feOffset>
                  <feGaussianBlur stdDeviation="6" in="shadowOffsetOuter2" result="shadowBlurOuter2"></feGaussianBlur>
                  <feColorMatrix values="0 0 0 0 0.0311856736   0 0 0 0 0.177658465   0 0 0 0 0.240432518  0 0 0 0.1 0"
                    type="matrix" in="shadowBlurOuter2" result="shadowMatrixOuter2"></feColorMatrix>
                  <feMerge>
                    <feMergeNode in="shadowMatrixOuter1"></feMergeNode>
                    <feMergeNode in="shadowMatrixOuter2"></feMergeNode>
                    <feMergeNode in="SourceGraphic"></feMergeNode>
                  </feMerge>
                </filter>
              </defs>
              <g id="Talk" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="0248.-VNCtalk--Archive-#04" transform="translate(-1815.000000, -320.000000)" fill="#337CBD">
                  <g id="Group-13" transform="translate(1607.000000, 266.000000)" filter="url(#filter-1)">
                    <g id="Stacked-Group" transform="translate(0.000000, 8.000000)">
                      <g id="Dropdown/item/@m/drop-default" transform="translate(0.000000, 36.000000)">
                        <g id="Container/16px" transform="translate(204.000000, 6.000000)">
                          <g id="Icon/product/check" transform="translate(4.000000, 4.000000)">
                            <g id="Icon-24px" transform="translate(2.000000, 3.333333)">
                              <polygon id="Shape"
                                points="4 7.44666667 1.22 4.66666667 0.273333333 5.60666667 4 9.33333333 12 1.33333333 11.06 0.393333333">
                              </polygon>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </svg>
          </div>
        </div>
      </mat-option>
      <mat-option [value]="groupType.DATE_DESC">
        <div class="main-justify">
          <div>
            <span>{{ 'SORT_BY_DESC' | translate }}</span>
          </div>
          <div class="icon-item">
            <svg *ngIf="groupControl.value === groupType.DATE_DESC" class="checked-icon" width="16px" height="16px"
              viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
              <defs>
                <filter x="-28.8%" y="-78.4%" width="157.5%" height="256.8%" filterUnits="objectBoundingBox" id="filter-1">
                  <feOffset dx="0" dy="8" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
                  <feGaussianBlur stdDeviation="8" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
                  <feColorMatrix values="0 0 0 0 0.031372549   0 0 0 0 0.176470588   0 0 0 0 0.239215686  0 0 0 0.2 0"
                    type="matrix" in="shadowBlurOuter1" result="shadowMatrixOuter1"></feColorMatrix>
                  <feOffset dx="0" dy="4" in="SourceAlpha" result="shadowOffsetOuter2"></feOffset>
                  <feGaussianBlur stdDeviation="6" in="shadowOffsetOuter2" result="shadowBlurOuter2"></feGaussianBlur>
                  <feColorMatrix values="0 0 0 0 0.0311856736   0 0 0 0 0.177658465   0 0 0 0 0.240432518  0 0 0 0.1 0"
                    type="matrix" in="shadowBlurOuter2" result="shadowMatrixOuter2"></feColorMatrix>
                  <feMerge>
                    <feMergeNode in="shadowMatrixOuter1"></feMergeNode>
                    <feMergeNode in="shadowMatrixOuter2"></feMergeNode>
                    <feMergeNode in="SourceGraphic"></feMergeNode>
                  </feMerge>
                </filter>
              </defs>
              <g id="Talk" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="0248.-VNCtalk--Archive-#04" transform="translate(-1815.000000, -320.000000)" fill="#337CBD">
                  <g id="Group-13" transform="translate(1607.000000, 266.000000)" filter="url(#filter-1)">
                    <g id="Stacked-Group" transform="translate(0.000000, 8.000000)">
                      <g id="Dropdown/item/@m/drop-default" transform="translate(0.000000, 36.000000)">
                        <g id="Container/16px" transform="translate(204.000000, 6.000000)">
                          <g id="Icon/product/check" transform="translate(4.000000, 4.000000)">
                            <g id="Icon-24px" transform="translate(2.000000, 3.333333)">
                              <polygon id="Shape"
                                points="4 7.44666667 1.22 4.66666667 0.273333333 5.60666667 4 9.33333333 12 1.33333333 11.06 0.393333333">
                              </polygon>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </svg>
          </div>
        </div>
      </mat-option>
    </mat-select>
  </div>

  <div [class.is-left-bar-expanded]="isLeftBarExpanded && !isRightBarExpanded" [class.is-right-bar-expanded]="isRightBarExpanded && !isLeftBarExpanded" class="vnc-kh-show-loader"><vnc-loader-circular *ngIf="(channels | async).length === 0 && !dismissLoader" [color]="'white'" [size]="'s'">
  </vnc-loader-circular>
  </div>

  <div  class="mobile-body-detail vnc-scroll-bar--small--vertical archive-channels-list display-grid vnc-scroll-bar--medium--vertical"
       vpInfiniteScroll
       *ngIf="data.sort !== groupType.AUTHOR_ASC && data.sort !== groupType.AUTHOR_DESC"
       scrollPercentage="0.8"
       (onUserScroll)="loadMoreChannels()">
    <vnc-channel-card class="archive-channel"
                      *ngFor="let channel of (channels | async)"
                      [channel]="channel"
                      [createdOn]="('CREATED' | translate) + ' ' + (getDateTimeInFormat(channel?.created_on))"
                      [menuTemplate]="menuTemplate"
                      (usersClicked)="openChannelParticipantsPopup(channel)"
                      (unArchive)="unArchiveChannel(channel)"
                      (infoClicked)="showChannelInfo(channel,$event);channelRepo.updateChannelIntoStore(channel);"
                      (delete)="permanentlyDeleteChannel(channel)"
                      kebabTooltipLabel="{{ 'MORE_TEXT' | translate }}"
                      restoreText="{{ 'UNARCHIVE_CHANNEL' | translate }}"
                      deleteText="{{ 'DELETE_CHANNEL' | translate }}"
                      infoText="{{ 'CHANNEL_INFO' | translate }}"
                      participantText="{{ 'PARTICIPANTS' | translate }}"
    ></vnc-channel-card>
  </div>
</div>

<div class="no-results" *ngIf="(channels | async).length === 0">
  <svg width="160" height="160" viewBox="0 0 160 160" xmlns="http://www.w3.org/2000/svg">
    <g fill="#A8B0B9" fill-rule="nonzero">
      <path
        d="M43 61.172 45.828 64 43 66.828 40.172 64zm8 0L53.828 64 51 66.828 48.172 64zm8 0L61.828 64 59 66.828 56.172 64zm8 0L69.828 64 67 66.828 64.172 64zm8 0L77.828 64 75 66.828 72.172 64zm8 0L85.828 64 83 66.828 80.172 64zm8 0L93.828 64 91 66.828 88.172 64zm24 0L117.828 64 115 66.828 112.172 64zm8 0L125.828 64 123 66.828 120.172 64zm-88 0L37.828 64 35 66.828 32.172 64zm72 0L109.828 64 107 66.828 104.172 64zm-8 0L101.828 64 99 66.828 96.172 64zm-56 14L45.828 78 43 80.828 40.172 78zm8 0L53.828 78 51 80.828 48.172 78zm8 0L61.828 78 59 80.828 56.172 78zm8 0L69.828 78 67 80.828 64.172 78zm8 0L77.828 78 75 80.828 72.172 78zm8 0L85.828 78 83 80.828 80.172 78zm8 0L93.828 78 91 80.828 88.172 78zm24 0L117.828 78 115 80.828 112.172 78zm8 0L125.828 78 123 80.828 120.172 78zm-88 0L37.828 78 35 80.828 32.172 78zm72 0L109.828 78 107 80.828 104.172 78zm-8 0L101.828 78 99 80.828 96.172 78zm-52-7L49.828 71 47 73.828 44.172 71zm8 0L57.828 71 55 73.828 52.172 71zm8 0L65.828 71 63 73.828 60.172 71zm8 0L73.828 71 71 73.828 68.172 71zm8 0L81.828 71 79 73.828 76.172 71zm8 0L89.828 71 87 73.828 84.172 71zm8 0L97.828 71 95 73.828 92.172 71zm24 0L121.828 71 119 73.828 116.172 71zm-80 0L41.828 71 39 73.828 36.172 71zm72 0L113.828 71 111 73.828 108.172 71zm-8 0L105.828 71 103 73.828 100.172 71zM33 88h93v1H33z"
        opacity=".6" />
      <path
        d="M80.611 96.5c10.555 0 19.111 8.556 19.111 19.111 0 4.557-1.595 8.741-4.257 12.026l13.45 13.449-2.83 2.828-13.448-13.449a19.031 19.031 0 0 1-12.026 4.257c-10.555 0-19.111-8.556-19.111-19.11 0-10.556 8.556-19.112 19.111-19.112zm0 4c-8.346 0-15.111 6.765-15.111 15.111s6.765 15.111 15.111 15.111 15.111-6.765 15.111-15.11c0-8.347-6.765-15.112-15.11-15.112zm37.733-77.844c6.323 0 11.464 5.044 11.621 11.33l.004.295V48.72h-.29a11.611 11.611 0 0 1 5.099 9.33l.003.295v43.312c0 6.324-5.044 11.465-11.33 11.622l-.295.003H104.72v-4h18.437c4.13 0 7.491-3.279 7.621-7.378l.004-.247V58.344c0-4.13-3.279-7.491-7.378-7.621l-.247-.004H67.53c-4.9 0-9.265-3.075-10.927-7.665l-.1-.288-.424-1.274a7.628 7.628 0 0 0-6.96-5.206l-.272-.005H36.531c-4.13 0-7.49 3.28-7.62 7.378l-.005.247v57.75c0 4.13 3.28 7.491 7.378 7.621l.247.004H55.97v4H36.53c-6.323 0-11.464-5.044-11.621-11.33l-.004-.295v-57.75c0-6.323 5.044-11.464 11.33-11.621l.295-.004h12.315c4.899 0 9.264 3.074 10.927 7.66l.1.289.424 1.273a7.626 7.626 0 0 0 6.96 5.21l.272.006h55.627c.97 0 1.912.118 2.813.342V34.28c0-4.13-3.28-7.49-7.378-7.62l-.247-.005h-77v-4h77z" />
    </g>
  </svg>

  <h3>{{ 'CHANNELS' | translate }}</h3>
  <p>{{'NO' | translate}} {{ 'CHANNELS' | translate }}</p>
</div>

<ng-template #menuTemplate let-item="item">
  <div class="item-drop-down">
    <vp-dropdown-item [itemType]="'icon-drop-default'"  (onClickEvent)="showChannelInfo(item);channelRepo.updateChannelIntoStore(item)" [prefixIconTemplate]="infoImage" label="{{'CHANNEL_INFO' | translate }}">
    </vp-dropdown-item>
  </div>
  <div class="item-drop-down">
    <vp-dropdown-item [itemType]="'icon-drop-default'"  (onClickEvent)="openChannelParticipantsPopup(item)" [prefixIconTemplate]="participantIcon" label="{{'PARTICIPANTS_LIST' | translate }}">
    </vp-dropdown-item>
  </div>
  <div class="item-drop-down" *ngIf="item.permissions && item.permissions[1] && item.permissions[1]?.moderate_channel">
    <vp-dropdown-item [itemType]="'icon-drop-default'"  (onClickEvent)="unArchiveChannel(item)" [prefixIconTemplate]="unArchiveIcon" label="{{'UNARCHIVE_CHANNEL' | translate }}">
    </vp-dropdown-item>
  </div>
  <div class="item-drop-down" *ngIf="item.permissions && item.permissions[1] && item.permissions[1]?.moderate_channel">
    <vp-dropdown-item [itemType]="'icon-drop-default'"  (onClickEvent)="permanentlyDeleteChannel(item)" [prefixIconTemplate]="deleteIcon" label="{{'DELETE_CHANNEL' | translate }}">
    </vp-dropdown-item>
  </div>
</ng-template>
<ng-template #unArchiveIcon>
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="Icon/product/archive-new" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <path d="M18.6666667,3 C19.9344145,3 20.9184865,4.06669955 20.9951839,5.36090909 L21,5.52380952 L21,18.3673469 C21,19.725145 20.0830737,20.9022238 18.8255848,20.9942177 L18.6666667,21 L5.33333333,21 C4.04846493,21 3.08014386,19.8772331 3.00473396,18.536065 L3,18.3673469 L3,5.52380952 C3,4.21209774 3.9333621,3.09292972 5.17638745,3.00549502 L5.33333333,3 L18.6666667,3 Z M19,9.571 L5,9.571 L5,18.3673469 C5,18.669907 5.12036977,18.8845341 5.23708236,18.9649827 L5.28651654,18.9911109 L5.33333333,19 L18.6666667,19 C18.7860931,19 18.9565804,18.8074886 18.992992,18.4914971 L19,18.3673469 L19,9.571 Z M14.25,11 C14.6642136,11 15,11.3459618 15,11.7727273 C15,12.1994928 14.6642136,12.5454545 14.25,12.5454545 L9.75,12.5454545 C9.33578644,12.5454545 9,12.1994928 9,11.7727273 C9,11.3459618 9.33578644,11 9.75,11 L14.25,11 Z M18.6666667,5 L5.33333333,5 C5.19805449,5 5.03989688,5.16357329 5.00642086,5.4224126 L5,5.52380952 L5,7.571 L19,7.571 L19,5.52380952 C19,5.27741957 18.8904187,5.09743045 18.7701099,5.02960269 L18.7181805,5.00753409 L18.6666667,5 Z" id="Combined-Shape" fill="#8B96A0"></path>
    </g>
  </svg>
</ng-template>
<ng-template #archiveIcon>
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="Icon/product/archive-new" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <path d="M18.6666667,3 C19.9344145,3 20.9184865,4.06669955 20.9951839,5.36090909 L21,5.52380952 L21,18.3673469 C21,19.725145 20.0830737,20.9022238 18.8255848,20.9942177 L18.6666667,21 L5.33333333,21 C4.04846493,21 3.08014386,19.8772331 3.00473396,18.536065 L3,18.3673469 L3,5.52380952 C3,4.21209774 3.9333621,3.09292972 5.17638745,3.00549502 L5.33333333,3 L18.6666667,3 Z M19,9.571 L5,9.571 L5,18.3673469 C5,18.669907 5.12036977,18.8845341 5.23708236,18.9649827 L5.28651654,18.9911109 L5.33333333,19 L18.6666667,19 C18.7860931,19 18.9565804,18.8074886 18.992992,18.4914971 L19,18.3673469 L19,9.571 Z M14.25,11 C14.6642136,11 15,11.3459618 15,11.7727273 C15,12.1994928 14.6642136,12.5454545 14.25,12.5454545 L9.75,12.5454545 C9.33578644,12.5454545 9,12.1994928 9,11.7727273 C9,11.3459618 9.33578644,11 9.75,11 L14.25,11 Z M18.6666667,5 L5.33333333,5 C5.19805449,5 5.03989688,5.16357329 5.00642086,5.4224126 L5,5.52380952 L5,7.571 L19,7.571 L19,5.52380952 C19,5.27741957 18.8904187,5.09743045 18.7701099,5.02960269 L18.7181805,5.00753409 L18.6666667,5 Z" id="Combined-Shape" fill="#8B96A0"></path>
    </g>
  </svg>
</ng-template>
<ng-template #infoImage>
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="Icon/product/info-outline" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <path d="M11,17 L13,17 L13,11 L11,11 L11,17 L11,17 Z M12,2 C6.48,2 2,6.48 2,12 C2,17.52 6.48,22 12,22 C17.52,22 22,17.52 22,12 C22,6.48 17.52,2 12,2 L12,2 Z M12,20 C7.59,20 4,16.41 4,12 C4,7.59 7.59,4 12,4 C16.41,4 20,7.59 20,12 C20,16.41 16.41,20 12,20 L12,20 Z M11,9 L13,9 L13,7 L11,7 L11,9 L11,9 Z" id="icon" fill="#8B96A0"></path>
    </g>
  </svg>
</ng-template>
<ng-template #deleteIcon>
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="Icon/product/delete-new" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <path d="M18,7 L18,18.2857143 C18,19.7746499 16.8086874,21 15.3333333,21 L15.3333333,21 L8.66666667,21 C7.19131256,21 6,19.7746499 6,18.2857143 L6,18.2857143 L6,7 L18,7 Z M16,9 L8,9 L8,18.2857143 C8,18.649428 8.25938319,18.9465317 8.57834889,18.9935231 L8.57834889,18.9935231 L8.66666667,19 L15.3333333,19 C15.6913126,19 16,18.6824929 16,18.2857143 L16,18.2857143 L16,9 Z M13,3 C13.7404864,3 14.3869825,3.40242038 14.7327215,4.00049436 L18,4 C18.5522847,4 19,4.44771525 19,5 C19,5.55228475 18.5522847,6 18,6 L6,6 C5.44771525,6 5,5.55228475 5,5 C5,4.44771525 5.44771525,4 6,4 L9.26760632,3.99992752 C9.61342606,3.40216612 10.2597476,3 11,3 L13,3 Z" id="Combined-Shape" fill="#8B96A0"></path>
    </g>
  </svg>
</ng-template>
<ng-template #participantIcon>
  <svg height="18" viewBox="0 0 18 18" width="18" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd"><g fill="#8B96A0"><g><g><g><path d="M.75 14.25v-2.303l.007-.103c.144-1.04.919-1.787 2.19-2.243C4.057 9.202 5.08 9 6.015 9c.919 0 1.916.195 2.992.579l-.06.022C10.057 9.202 11.08 9 12.015 9c.936 0 1.954.202 3.055.602 1.198.434 1.95 1.13 2.147 2.089l.026.153.007.103v2.303H.75zm11.265-3.75c-.406 0-.842.05-1.308.15.258.303.43.65.51 1.04l.026.154.007.103v.803h4.5v-.736l-.008-.044c-.084-.346-.4-.646-1.032-.9l-.151-.058c-.946-.343-1.795-.512-2.544-.512zm-6 0c-.75 0-1.604.169-2.56.513-.743.266-1.106.586-1.197.957l-.008.04v.74h7.5v-.736l-.008-.044c-.078-.32-.352-.6-.891-.841l-.14-.06-.152-.057c-.946-.343-1.795-.512-2.544-.512zM5.625 3c.735 0 1.374.25 1.872.745.499.495.753 1.132.753 1.865 0 .733-.254 1.37-.753 1.866-.49.486-1.113.747-1.884.774-.735-.027-1.368-.286-1.86-.774C3.254 6.98 3 6.343 3 5.61c0-.733.254-1.37.753-1.865C4.251 3.25 4.89 3 5.625 3zm6.75 0c.735 0 1.374.25 1.872.745.499.495.753 1.132.753 1.865 0 .733-.254 1.37-.753 1.866-.492.488-1.125.747-1.914.773-.717-.026-1.34-.287-1.83-.773-.499-.496-.753-1.133-.753-1.866 0-.733.254-1.37.753-1.865C11.001 3.25 11.64 3 12.375 3zm-6.75 1.5c-.348 0-.605.1-.815.31-.21.207-.31.46-.31.8 0 .341.1.593.31.801.216.215.489.326.802.34.347-.013.61-.124.828-.34.21-.208.31-.46.31-.8 0-.342-.1-.594-.31-.801-.21-.21-.467-.31-.815-.31zm6.75 0c-.348 0-.605.1-.815.31-.21.207-.31.46-.31.8 0 .341.1.593.31.801.218.216.481.327.774.34.367-.014.64-.125.856-.34.21-.208.31-.46.31-.8 0-.342-.1-.594-.31-.801-.21-.21-.467-.31-.815-.31z" transform="translate(-23 -138) translate(16 131) translate(4 4) translate(3 3)"></path></g></g></g></g></g></svg>
</ng-template>
