
/*
 * VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Injectable } from "@angular/core";
import { MiddlewareService } from "./middleware.service";
import { BehaviorSubject } from "rxjs";

@Injectable()
export class VNCDirectoryService {
  public audienceList$ = new BehaviorSubject<string[]>([]);
  constructor(private middlewareService: MiddlewareService) {
  }

  createSearchQuery(body: any) {
    return this.middlewareService.post(`/api/queries`, true, body);
  }

  getSearchQueries(q: string = "") {
    return this.middlewareService.get(`/api/queries?q=${q}`, true);
  }

  deleteSearchQuery(id: number) {
    return this.middlewareService.delete(`/api/queries/${id}`, true);
  }

  deleteAllSearchQueries() {
    return this.middlewareService.delete(`/api/queries/delete_all`, true);
  }

  getSearchFilters(queryClass: string) {
    return this.middlewareService.get(`/api/queries/filters?query_class=${queryClass}`, true);
  }

  updateLang(language: string) {
    const lang = {
      lang: language
    };
    return this.middlewareService.post("/api/updateLang", true, lang);
  }

  getArchivedWorkflows() {
    return this.middlewareService.get("/api/archived-workspaces", true);
  }

  UnArchiveWorkflows(id) {
    return this.middlewareService.post("/api/unarchived-workspaces", true, {id: id},);
  }

  DeleteArchiveWorkflows(id) {
    return this.middlewareService.delete("/api/delete-archived-workspaces?id=" + id, true);
  }


}
